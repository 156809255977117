/*
* ink colors (with variations only for black and white)
*/
.slider-container {
  width: 100%; }
  .slider-container .focusRing___1airF.carousel__slide-focus-ring {
    outline: none !important; }

.slider-controllers-wrapper {
  width: 100%;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .slider-controllers-wrapper .slider-indicator-wrapper {
    display: flex;
    flex: 1; }
    .slider-controllers-wrapper .slider-indicator-wrapper .slider-indicator {
      flex: 1;
      height: 4px;
      background-color: #D6D6D6; }
    .slider-controllers-wrapper .slider-indicator-wrapper .slider-indicator:first-of-type {
      border-radius: 8px 0 0 8px; }
    .slider-controllers-wrapper .slider-indicator-wrapper .slider-indicator:nth-last-child(2) {
      border-radius: 0 8px 8px 0; }
    .slider-controllers-wrapper .slider-indicator-wrapper .slider-indicator-active {
      background-color: #ED6969; }
  .slider-controllers-wrapper .slider-controllers {
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-left: 16px; }
    .slider-controllers-wrapper .slider-controllers .slider-controller-item {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      outline: none;
      height: 32px;
      width: 32px;
      background-color: white;
      border-radius: 4px;
      box-shadow: 0 2px 8px #14141429; }
      .slider-controllers-wrapper .slider-controllers .slider-controller-item .icon {
        height: 24px;
        width: 24px;
        filter: invert(26%) sepia(90%) saturate(1676%) hue-rotate(204deg) brightness(103%) contrast(95%); }
    .slider-controllers-wrapper .slider-controllers .slider-controller-item:hover {
      background-color: #1D66DD; }
      .slider-controllers-wrapper .slider-controllers .slider-controller-item:hover .icon {
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(21deg) brightness(103%) contrast(102%); }
